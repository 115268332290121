import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment.development';
import { JwtHelperService } from '@auth0/angular-jwt';
import {RoleEnum} from "../models/role";

interface loginResponse{
  token_type: string,
  access_token: string,
  expires_in: number,
  refresh_token: string,
  refresh_expires_in: string
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  private loginResponse: BehaviorSubject<loginResponse>;
  public currentUser: Observable<User>;
  private readonly API_URL = environment.apiUrl;

  constructor(private http: HttpClient,private jwtHelper: JwtHelperService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );

    this.loginResponse = new BehaviorSubject<loginResponse>(
      JSON.parse('{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<loginResponse>(`${environment.apiUrl}/v1/auth/login`, {
        email,
        password,
      })
      .pipe(
        map((response) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.loginResponse.next(response);
          const {access_token} = response
          const decodedToken = this.jwtHelper.decodeToken(access_token)
          const user = decodedToken?.user
          user.token = access_token
          user.role = user.role.name
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  createPassword(userId: string, password: string): Observable<any>{
    return this.http.patch(`${environment.apiUrl}/v1/users/${userId}`, {password})
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(this.currentUserValue);
    return of({ success: false });
  }
}
