import {DOCUMENT} from '@angular/common';
import {
    Component,
    Inject,
    ElementRef,
    OnInit,
    Renderer2,
    AfterViewInit,
} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from 'src/app/config/config.service';
import {AuthService} from 'src/app/core/service/auth.service';
import {RightSidebarService} from 'src/app/core/service/rightsidebar.service';
import {LanguageService} from 'src/app/core/service/language.service';
import {UnsubscribeOnDestroyAdapter} from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import {InConfiguration} from 'src/app/core/models/config.interface';
import {User} from "../../core/models/user";

interface Notifications {
    message: string;
    time: string;
    icon: string;
    color: string;
    status: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass'],
})
export class HeaderComponent
    extends UnsubscribeOnDestroyAdapter
    implements OnInit, AfterViewInit {
    public config!: InConfiguration;
    homePage!: string;
    isNavbarCollapsed = true;
    flagvalue: string | string[] | undefined;
    countryName: string | string[] = [];
    langStoreValue!: string;
    defaultFlag!: string;
    isOpenSidebar!: boolean;
    docElement: HTMLElement | undefined;
    isFullScreen = false;
    currentUser: User = {} as User

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public elementRef: ElementRef,
        private rightSidebarService: RightSidebarService,
        private configService: ConfigService,
        private authService: AuthService,
        private router: Router,
        public languageService: LanguageService
    ) {
        super();
    }

    listLang = [
        {text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en'},
        {text: 'Spanish', flag: 'assets/images/flags/spain.svg', lang: 'es'},
        {text: 'French', flag: 'assets/images/flags/france.jpg', lang: 'fr'},
    ];
    notifications: Notifications[] = [
        {
            message: 'Please check your mail',
            time: '14 mins ago',
            icon: 'mail',
            color: 'nfc-green',
            status: 'msg-unread',
        },
        {
            message: 'New Patient Added..',
            time: '22 mins ago',
            icon: 'person_add',
            color: 'nfc-blue',
            status: 'msg-read',
        },
        {
            message: 'Your leave is approved!! ',
            time: '3 hours ago',
            icon: 'event_available',
            color: 'nfc-orange',
            status: 'msg-read',
        },
        {
            message: 'Lets break for lunch...',
            time: '5 hours ago',
            icon: 'lunch_dining',
            color: 'nfc-blue',
            status: 'msg-read',
        },
        {
            message: 'Patient report generated',
            time: '14 mins ago',
            icon: 'description',
            color: 'nfc-green',
            status: 'msg-read',
        },
        {
            message: 'Please check your mail',
            time: '22 mins ago',
            icon: 'mail',
            color: 'nfc-red',
            status: 'msg-read',
        },
        {
            message: 'Salary credited...',
            time: '3 hours ago',
            icon: 'paid',
            color: 'nfc-purple',
            status: 'msg-read',
        },
    ];
    ngOnInit() {
        this.config = this.configService.configData;
        this.currentUser = this.authService.currentUserValue;

        this.docElement = document.documentElement;

        if (this.currentUser.role === 'Admin') {
            this.homePage = 'admin/dashboard/main';
        } else if (this.currentUser.role === 'Attendant') {
            this.homePage = 'attendant/dashboard';
        } else if (this.currentUser.role === 'Doctor') {
            this.homePage = 'doctor/dashboard';
        } else {
            this.homePage = 'admin/dashboard/main';
        }

        this.langStoreValue = localStorage.getItem('lang') as string;
        const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
        this.countryName = val.map((element) => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.defaultFlag = 'assets/images/flags/us.svg';
            }
        } else {
            this.flagvalue = val.map((element) => element.flag);
        }
    }

    ngAfterViewInit() {
        // set theme on startup
        if (localStorage.getItem('theme')) {
            this.renderer.removeClass(this.document.body, this.config.layout.variant);
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('theme') as string
            );
        } else {
            this.renderer.addClass(this.document.body, this.config.layout.variant);
        }

        if (localStorage.getItem('menuOption')) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('menuOption') as string
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                'menu_' + this.config.layout.sidebar.backgroundColor
            );
        }

        if (localStorage.getItem('choose_logoheader')) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('choose_logoheader') as string
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                'logo-' + this.config.layout.logo_bg_color
            );
        }

        if (localStorage.getItem('sidebar_status')) {
            if (localStorage.getItem('sidebar_status') === 'close') {
                this.renderer.addClass(this.document.body, 'side-closed');
                this.renderer.addClass(this.document.body, 'submenu-closed');
            } else {
                this.renderer.removeClass(this.document.body, 'side-closed');
                this.renderer.removeClass(this.document.body, 'submenu-closed');
            }
        } else {
            if (this.config.layout.sidebar.collapsed === true) {
                this.renderer.addClass(this.document.body, 'side-closed');
                this.renderer.addClass(this.document.body, 'submenu-closed');
            }
        }
    }

    callFullscreen() {
        if (!this.isFullScreen) {
            this.docElement?.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
    }

    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.langStoreValue = lang;
        this.languageService.setLanguage(lang);
    }

    mobileMenuSidebarOpen(event: Event, className: string) {
        const hasClass = (event.target as HTMLInputElement).classList.contains(
            className
        );
        if (hasClass) {
            this.renderer.removeClass(this.document.body, className);
        } else {
            this.renderer.addClass(this.document.body, className);
        }
    }

    callSidemenuCollapse() {
        const hasClass = this.document.body.classList.contains('side-closed');
        if (hasClass) {
            this.renderer.removeClass(this.document.body, 'side-closed');
            this.renderer.removeClass(this.document.body, 'submenu-closed');
        } else {
            this.renderer.addClass(this.document.body, 'side-closed');
            this.renderer.addClass(this.document.body, 'submenu-closed');
        }
    }

    userProfile() {
        this.router.navigate(['/admin/security/user-profile/', this.currentUser.id]);
    }

    logout() {
        this.subs.sink = this.authService.logout().subscribe((res) => {
            if (!res.success) {
                this.router.navigate(['/authentication/signin']);
            }
        });
    }
}
