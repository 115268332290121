import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules
  {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.HOME.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.BRANCHES.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'device_hub',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/branches/all',
        title: 'MENUITEMS.BRANCHES.ALL-BRANCHES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/branches/add-branch',
        title: 'MENUITEMS.BRANCHES.ADD-BRANCH',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/branches/report',
        title: 'MENUITEMS.BRANCHES.BRANCH-REPORT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.PRODUCTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'stars',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/products/all',
        title: 'MENUITEMS.PRODUCTS.ALL-PRODUCTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/products/add',
        title: 'MENUITEMS.PRODUCTS.ADD-PRODUCT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.ORDERS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'description',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/orders/all',
        title: 'MENUITEMS.ORDERS.ALL-ORDERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.CLIENTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/clients/all',
        title: 'MENUITEMS.CLIENTS.ALL-CLIENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/admin/clients/report',
        title: 'MENUITEMS.CLIENTS.CLIENT-REPORT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.CHARGES.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/charges/all',
        title: 'MENUITEMS.CHARGES.ALL-CHARGES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.REPORTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'info',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/billing/bill-list',
  //       title: 'MENUITEMS.REPORTS.GENERAL-REPORTS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/billing/add-bill',
  //       title: 'MENUITEMS.REPORTS.TURNOVER',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/billing/invoice',
  //       title: 'MENUITEMS.REPORTS.AMORTIZATION',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: '',
    title: 'MENUITEMS.SECURITY.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'security',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/admin/security/users/all',
        title: 'MENUITEMS.SECURITY.LIST.USERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },

  // Doctor Modules
  {
    path: '/doctor/dashboard',
    title: 'MENUITEMS.DOCTOR.DASHBOARD',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/doctor/appointments',
    title: 'MENUITEMS.DOCTOR.APPOINTMENTS',
    iconType: 'material-icons-two-tone',
    icon: 'assignment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/doctor/doctors',
    title: 'MENUITEMS.DOCTOR.DOCTORS',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/doctor/patients',
    title: 'MENUITEMS.DOCTOR.PATIENTS',
    iconType: 'material-icons-two-tone',
    icon: 'face',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/doctor/settings',
    title: 'MENUITEMS.DOCTOR.SETTINGS',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },
  {
    path: '/apps/chat',
    title: 'MENUITEMS.DOCTOR.CHAT',
    iconType: 'material-icons-two-tone',
    icon: 'chat',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [],
  },

  // Attendant Modules
  {
    path: '/attendant/dashboard',
    title: 'MENUITEMS.HOME.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Attendant'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.CLIENTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Attendant'],
    submenu: [
      {
        path: '/attendant/clients/all',
        title: 'MENUITEMS.CLIENTS.ALL-CLIENTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/attendant/clients/add',
        title: 'MENUITEMS.CLIENTS.ADD-CLIENT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      }
    ],
  },
  {
    path: '/attendant/',
    title: 'MENUITEMS.ORDERS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'description',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Attendant'],
    submenu: [
      {
        path: '/attendant/orders/all',
        title: 'MENUITEMS.ORDERS.ALL-ORDERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/attendant/orders/add',
        title: 'MENUITEMS.ORDERS.ADD-ORDER',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.PRODUCTS.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'stars',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Attendant'],
    submenu: [
      {
        path: '/attendant/products/all',
        title: 'MENUITEMS.PRODUCTS.ALL-PRODUCTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.CHARGES.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Attendant'],
    submenu: [
      {
        path: '/attendant/charges/all',
        title: 'MENUITEMS.CHARGES.ALL-CHARGES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  }
];
