/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component {
  constructor() {}
}
